import { Box, Stack, Typography } from '@mui/joy';
import { type FunctionComponent, useEffect } from 'react';
import { clearImpersonatedCognitoUsername } from '../../AuthStorage.ts';

const Home: FunctionComponent = () => {
  useEffect(() => {
    clearImpersonatedCognitoUsername();
    sessionStorage.clear();
  }, []);

  return (
    <Box
      sx={{
        padding: '3rem',
      }}
    >
      <Stack spacing={1.5}>
        <Typography level="h2">Welcome to Genie!</Typography>
        <Typography level={'title-sm'}>To proceed to the application, please log in first.</Typography>
      </Stack>
    </Box>
  );
};

export default Home;
